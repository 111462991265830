<template>
  <div class="flex flex-col gap-10 w-full">
    <div class="flex-1 flex flex-col gap-0">
      <fw-heading size="h2">{{ $t('enter_exam') }}</fw-heading>
      <fw-label class="mb-1 mt-2">{{ $t('enter_exam_code') }}</fw-label>
      <form class="flex flex-col gap-3" @submit.prevent="checkEntryAndGo">
        <div class="flex justify-center items-center gap-3">
          <b-input
            id="code1"
            ref="code1"
            v-model="code1"
            type="number"
            min="0"
            max="9"
            class="codeinput"
            :class="{ error: errorMessage }"
            placeholder=""
            @input="nextCode(1)"
          />
          <b-input
            id="code2"
            ref="code2"
            v-model="code2"
            type="number"
            min="0"
            max="9"
            class="codeinput"
            :class="{ error: errorMessage }"
            placeholder=""
            @input="nextCode(2)"
          />
          <b-input
            id="code3"
            ref="code3"
            v-model="code3"
            type="number"
            min="0"
            max="9"
            class="codeinput"
            :class="{ error: errorMessage }"
            placeholder=""
            @input="nextCode(3)"
          />
          <div class="text-gray-400 text-4xl">-</div>
          <b-input
            id="code4"
            ref="code4"
            v-model="code4"
            min="0"
            max="9"
            type="number"
            class="codeinput"
            :class="{ error: errorMessage }"
            placeholder=""
            @input="nextCode(4)"
          />
          <b-input
            id="code5"
            ref="code5"
            v-model="code5"
            min="0"
            max="9"
            type="number"
            class="codeinput"
            :class="{ error: errorMessage }"
            placeholder=""
            @input="nextCode(5)"
          />
          <b-input
            id="code6"
            ref="code6"
            v-model="code6"
            min="0"
            max="9"
            type="number"
            class="codeinput"
            :class="{ error: errorMessage }"
            placeholder=""
            @input="nextCode(6)"
          />
        </div>
        <div
          v-if="errorMessage"
          class="h-10 p-1 flex items-center justify-center text-center font-semibold rounded-md text-sm"
          :class="{ 'bg-red-500 bg-opacity-10 text-red-700': errorMessage }"
        >
          {{ errorMessage }}
        </div>
        <div class=" border-gray-500 p-0.5 rounded-lg bg-gray-100 my-3">
          <fw-person :person="me">
            <template #options>
              <fw-button type="xlight" @click.native="changeUser()">
                <fw-icon-logout-right class="h-6 w-6 text-gray-600" />
              </fw-button>
            </template>
          </fw-person>
        </div>
        <div>
          <fw-button
            type="black"
            size="md"
            :disabled="fullCode.length != 6 || loading"
            :loading="loading"
            expanded
            @click.native="findExamByCode"
            >{{ $t('enter') }}</fw-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ServiceExams from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showKeyboard: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: '',
      code7: '',
      code8: '',
      isActive: this.show || false,
      loading: false,
      errorMessage: '',
    }
  },

  computed: {
    me() {
      return this.$store.getters.getUser
    },
    fullCode() {
      return `${this.code1}${this.code2}${this.code3}${this.code4}${this.code5}${this.code6}`
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
    isMobile() {
      return this.window.width < 640
    },
  },

  watch: {
    show(value) {
      this.isActive = value
    },
  },

  mounted() {
    //focus on code1
    this.$refs.code1.focus()
    document.addEventListener('keydown', this.listenKeyboard)
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.listenKeyboard)
  },

  methods: {
    changeUser() {
      this.$buefy.dialog.confirm({
        title: this.$t('change_user'),
        message: this.$t('change_user_confirm'),
        confirmText: this.$t('change_user_confirm_yes'),
        cancelText: this.$t('change_user_confirm_no'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$router.push({ name: 'login' })
        },
      })
    },
    listenKeyboard(e) {
      if (e.key === 'Escape') {
        this.closeModal()
      } else if (e.key === 'Enter') {
        this.findExamByCode()
      } else if (e.key === 'Backspace') {
        //get the current focus element
        let id = document.activeElement.id
        let number = parseInt(id.replace('code', ''))
        if (number > 0) {
          this.deletedCode(number)
        }
      }
      //use left and right arrows to focus in diferent code
      else if (e.key === 'ArrowLeft') {
        let id = document.activeElement.id
        let number = parseInt(id.replace('code', ''))
        if (number > 1) {
          let ref = 'code' + (number - 1)
          if (this.$refs[ref]) {
            this.$refs[ref].focus()
          }
        }
      } else if (e.key === 'ArrowRight') {
        let id = document.activeElement.id
        let number = parseInt(id.replace('code', ''))
        if (number < 6) {
          let ref = 'code' + (number + 1)
          if (this.$refs[ref]) {
            this.$refs[ref].focus()
          }
        }
      }
    },
    deletedCode(id) {
      console.log('deletedCode', id)
      let ref = 'code' + id
      let prev = 'code' + (id - 1)
      let next = 'code' + (id + 1)
      if (this.$refs[prev] && this[ref] == '') {
        this.$refs[prev].focus()
      } else {
        if (this[ref].length == 1 && this.$refs[next]) {
          this.$refs[next].focus()
        }
      }
    },
    nextCode(id) {
      if (id < 6) {
        if (this['code' + id] != '') {
          let next = 'code' + (id + 1)
          if (this.$refs[next]) {
            this.$refs[next].focus()
          }
        }
      }
    },
    closeModal() {
      this.isActive = false
      this.code1 = ''
      this.code2 = ''
      this.code3 = ''
      this.code4 = ''
      this.code5 = ''
      this.code6 = ''
      this.errorMessage = ''
      this.$emit('close')
    },
    async findExamByCode() {
      if (this.fullCode) {
        this.loading = true

        // Reset message
        this.errorMessage = ''

        utils.tryAndCatch(
          this,
          async () => {
            const response = await ServiceExams.getInstanceByShortCodeKey(this.fullCode)
            this.$router.push({
              name: 'exams-examination',
              params: {
                key: response.instance.key,
              },
            })
          },
          () => {
            setTimeout(() => {
              this.loading = false
            }, 150)
          },
          error => {
            if (error.response.status === 404) {
              this.errorMessage = this.$t('code_not_found')
            } else {
              this.errorMessage = this.$t('error_finding_code')
            }
          },
          false
        )
      }
    },
  },
}
</script>

<style>
.control.codeinput input {
  width: 3rem;
  font-size: 1.5rem;
  text-align: center;
  padding: 0px;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: #fafafa;
}

.control.codeinput.error input {
  border: 1px solid #d74a4a;
  color: #d74a4a;
}

.control.codeinput input::-webkit-outer-spin-button,
.control.codeinput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.control.codeinput input[type='number'] {
  -moz-appearance: textfield;
}
</style>

<i18n>
{
  "pt": {
    "enter_exam": "Entrar no exame",
    "enter_exam_code": "Indique o código do exame:",
    "change_user": "Mudar de utilizador",
    "change_user_confirm": "Tem a certeza que deseja mudar de utilizador? Vai terminar sessão e voltar ao ecrã de login.",
    "change_user_confirm_yes": "Terminar sessão",
    "change_user_confirm_no": "Cancelar",
    "code_not_found": "O código não foi encontrado.",
    "error_finding_code": "Ocorreu um erro a tentar encontrar o código do exame.",
    "enter": "Entrar"
  },
  "en": {
    "enter_exam": "Enter exam",
    "enter_exam_code": "Enter the exam code:",
    "change_user": "Change user",
    "change_user_confirm": "Are you sure you want to change user? You will log out and return to the login screen.",
    "change_user_confirm_yes": "Log out",
    "change_user_confirm_no": "Cancel",
    "code_not_found": "The code was not found.",
    "error_finding_code": "An error occurred while trying to find the exam code.",
    "enter": "Enter"
  }
}
</i18n>
