<template>
  <fw-modal
    :active.sync="isActive"
    :can-cancel="true"
    size="full"
    :width="showKeyboard ? '50rem' : '30rem'"
    @close="closeModal"
  >
    <div class="flex flex-col gap-10 w-full">
      <div class="flex-1 flex flex-col gap-0">
        <fw-heading size="h2" class="mb-4">Ativar tablet</fw-heading>
        <fw-label>Indique o código de ativação</fw-label>
        <form class="flex flex-col gap-3" @submit.prevent="activateTablet">
          <div class="flex justify-center items-center gap-2">
            <b-input id="code1" ref="code1" v-model="code1" type="number" class="codeinput" placeholder="" />
            <b-input id="code2" ref="code2" v-model="code2" type="number" class="codeinput" placeholder="" />
            <b-input id="code3" ref="code3" v-model="code3" type="number" class="codeinput" placeholder="" />
            <div class="text-gray-400 text-4xl">-</div>
            <b-input id="code4" ref="code4" v-model="code4" type="number" class="codeinput" placeholder="" />
            <b-input id="code5" ref="code5" v-model="code5" type="number" class="codeinput" placeholder="" />
            <b-input id="code6" ref="code6" v-model="code6" type="number" class="codeinput" placeholder="" />
          </div>
          <div v-if="errorMessage" class="text-center p-2 bg-red-500 bg-opacity-10 rounded-lg text-red-900 text-sm">
            {{ errorMessage }}
          </div>
          <b-field label="Referência do tablet">
            <b-input v-model="reference" placeholder="Referência do tablet" />
          </b-field>
          <b-field label="Descrição do tablet (opcional)">
            <b-input v-model="description" placeholder="Descrição do tablet" />
          </b-field>
          <div>
            <fw-button
              type="primary"
              size="md"
              :disabled="fullCode.length != 6 || reference.length == 0 || loadingCheckEntry"
              :loading="loadingCheckEntry"
              expanded
              @click.native="activateTablet"
              >Ativar</fw-button
            >
          </div>
        </form>
      </div>
    </div>
  </fw-modal>
</template>

<script>
import ServicePages from '../../fw-modules/fw-core-vue/pages/services/ServicePages'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showKeyboard: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: '',
      code7: '',
      code8: '',
      isActive: this.show || false,
      entryKeyToJoin: null,
      latestEntriesJoined: [],
      duplicatedKeys: [],
      errorMessage: null,
      loadingCheckEntry: false,
      description: '',
      reference: '',
    }
  },

  computed: {
    fullCode() {
      return `${this.code1}${this.code2}${this.code3}${this.code4}${this.code5}${this.code6}`
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
    isMobile() {
      return this.window.width < 640
    },
  },

  watch: {
    show(value) {
      this.isActive = value
    },
    code1(value) {
      if (value.length == 1) {
        this.$refs.code2.focus()
      }
    },
    code2(value) {
      if (value.length == 1) {
        this.$refs.code3.focus()
      }
    },
    code3(value) {
      if (value.length == 1) {
        this.$refs.code4.focus()
      }
    },
    code4(value) {
      if (value.length == 1) {
        this.$refs.code5.focus()
      }
    },
    code5(value) {
      if (value.length == 1) {
        this.$refs.code6.focus()
      }
    },
  },

  methods: {
    closeModal() {
      this.isActive = false
      this.code1 = ''
      this.code2 = ''
      this.code3 = ''
      this.code4 = ''
      this.code5 = ''
      this.code6 = ''
      this.$emit('close')
    },
    async activateTablet() {
      try {
        this.loadingCheckEntry = true
        const tablet = await ServicePages.activateDigitalPad(this.fullCode, this.reference, this.description)
        this.loadingCheckEntry = false
        if (tablet.status == 'ok') {
          localStorage.setItem('deviceKey', tablet.device.key)
          localStorage.setItem('deviceRef', this.reference)
          localStorage.setItem('deviceDesc', this.description)
          console.log('tablet activated', tablet)
          this.$emit('activated', tablet.device.key)
          this.closeModal()
        }
      } catch (error) {
        console.log('error', error)
        if (error.response.data && error.response.data['__errors__']) {
          this.errorMessage = error.response.data['__errors__'][0].detail
        }
        //this.errorMessage = error.message
        this.loadingCheckEntry = false
      }
    },
  },
}
</script>

<style>
.control.codeinput input {
  width: 3rem;
  font-size: 1.5rem;
  text-align: center;
  padding: 0px;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: #fafafa;
}

.control.codeinput input::-webkit-outer-spin-button,
.control.codeinput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.control.codeinput input[type='number'] {
  -moz-appearance: textfield;
}
</style>
