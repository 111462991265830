<template>
  <div
    class="font-sans antialiased h-screen flex flex-col"
    :class="{
      'bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800': dark,
      'bg-gray-100': !dark,
    }"
  >
    <header
      v-if="header"
      class="
        main-header
        absolute
        z-50
        top-0
        left-0
        right-0
        w-full
        flex flex-none
        mx-auto
        items-center
        justify-center
        h-14
        sm:h-16
      "
      :class="{
        'text-white': dark,
        'bg-gray-100 bg-opacity-90': headerBackground && !whiteHeader,
        'bg-gray-50 bg-opacity-80': whiteHeader,
      }"
    >
      <div class="flex flex-none h-auto w-full pr-5 pl-0 items-center">
        <div
          class="
            flex
            items-center
            mr-5
            ml-3
            align-middle
            justify-between
            transition-all
            duration-150
            overflow-hidden
            group
          "
        >
          <fw-button
            v-if="backTo && !disableBackTo"
            type="light"
            size="sm"
            class="
              force-hover-opacicty-100
              flex
              -ml-8
              group-hover:ml-2 group-hover:opacity-50
              opacity-0
              items-center
              gap-1
              group
              transition-all
              duration-150
            "
            @click.native="getBackTo"
          >
            <span class="sr-only">Voltar à página anterior</span>
            <svg
              class="fill-current w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M5.828 7l2.536 2.536L6.95 10.95 2 6l4.95-4.95 1.414 1.414L5.828 5H13a8 8 0 1 1 0 16H4v-2h9a6 6 0 1 0 0-12H5.828z"
              />
            </svg>
          </fw-button>
          <AppLogo
            :class="{ 'text-white': dark, 'text-black': !dark, 'cursor-pointer': !disableBackTo }"
            class="ml-3"
            :size="'xs'"
            @click.native="backToHome"
          />
        </div>
        <div class="flex-1 h-18 flex items-center justify-between text-black">
          <div class="w-full relative flex flex-col gap-0.5">
            <div class="h-18 overflow-hidden">
              <div
                v-if="headerTitle"
                class="font-bold line-clamp-1 text-left transition-all relative"
                :class="[
                  {
                    'anim-title': showHeaderTitle,
                    'restore-title': !showHeaderTitle,
                    'text-lg': $slots['header-title-tags'],
                    'text-2xl': !$slots['header-title-tags'],
                  },
                  textHeaderClass,
                ]"
              >
                {{
                  typeof headerTitle === 'string'
                    ? headerTitle
                    : headerTitle[headerLanguage]
                    ? headerTitle[headerLanguage]
                    : 'Sem nome'
                }}
              </div>
            </div>
            <div
              v-if="$slots['header-title-tags'] && !isMobile"
              class="flex items-center gap-1.5 transition-all"
              :class="{ 'anim-subtags': showHeaderTitle, 'restore-subtags': !showHeaderTitle }"
            >
              <slot name="header-title-tags"></slot>
            </div>
          </div>
          <div>
            <slot name="header-nav"></slot>
          </div>
          <div
            v-if="$slots.headerToolbarPrefix || $slots.mHeaderToolbar || headerToolbar || headerSessionNav"
            class="pl-4 flex-shrink-0 flex align-middle justify-end space-x-6"
            :class="{ 'anim-header': !isMobile && animHeader }"
          >
            <div v-if="$slots.headerToolbarPrefix" class="flex items-center gap-4">
              <slot name="headerToolbarPrefix"></slot>
            </div>
            <div v-if="$slots.mHeaderToolbar" class="flex items-center sm:hidden gap-2">
              <slot name="m-header-toolbar"></slot>
            </div>
            <div v-if="headerToolbar && userLoggedIn && !isAnonymous" class="flex items-center md:gap-2">
              <slot name="header-toolbar"></slot>
              <BlockHeaderNotifications v-if="headerNotifications" />
              <BlockHeaderApps v-if="headerApps" :class="{ 'hidden sm:flex': hideHeaderAppsMobile }" />
              <slot name="header-toolbar-end"></slot>
            </div>
            <div v-if="headerSessionNav && userLoggedIn && !isMobile && !isAnonymous">
              <b-dropdown aria-role="list" scrollable position="is-bottom-left">
                <div slot="trigger" class="flex is-link items-center justify-center">
                  <Avatar size="sm" :user="user" />
                  <svg
                    class="fill-current w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </div>
                <b-dropdown-item custom aria-role="menuitem">
                  <div>
                    <v-clamp class="font-bold" autoresize :max-lines="2">{{
                      user.display_name || user.full_name
                    }}</v-clamp>
                    <div v-if="user.number" class="text-gray-500">Nº {{ user.number }}</div>
                    <v-clamp class="text-sm text-gray-500" autoresize :max-lines="1">{{ user.email }}</v-clamp>
                  </div>
                </b-dropdown-item>
                <hr class="dropdown-divider" />
                <b-dropdown-item has-link aria-role="menuitem">
                  <router-link to="/account" tag="a"> Conta </router-link>
                  <router-link to="/logout" tag="a">Terminar sessão</router-link>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div v-if="!headerSessionNav && headerAvatar && userLoggedIn && !isMobile && !isAnonymous">
              <Avatar size="sm" :user="user" />
            </div>
            <div v-if="headerSessionNav && !userLoggedIn && $router.currentRoute.name != 'login'">
              <fw-button class="flex items-center gap-2" @click.native="$router.push({ name: 'login' })">
                <fw-icon-user class="w-6 h-6" /> Entrar
              </fw-button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main
      class="flex w-full h-auto flex-1 pt-14 overflow-x-hidden"
      :class="{ 'text-white': dark, 'overflow-y-auto': !noScroll, 'overflow-y-hidden': noScroll }"
    >
      <slot name="main">
        <div
          v-if="mainSidebar || mainSidebarSm"
          class="main-sidebar h-full hidden lg:block flex-shrink-0"
          :class="[mainSideBarComputed]"
          @click.self="focusMainBackdrop()"
        >
          <div :class="[{ fixed: mainSidebarFixed }, mainSideBarComputed]" class="h-full overflow-y-auto">
            <slot name="main-sidebar"></slot>
          </div>
        </div>
        <!--
        :class="{
            'xl:max-w-screen-xl mx-auto': !full && !wide,
            'xl:max-w-screen-2xl mx-auto': !full && wide,
            'pt-5 lg:pl-56': mainSidebar && !full && paddingless,
            'p-5 lg:pl-56': mainSidebar && !full && !paddingless,
            'lg:pl-12 lg:pr-12': !mainSidebar && !full,
            'flex flex-col pr-0': full,
            'lg:pl-20': full && mainSidebarSm,
            'lg:pl-56': full && !mainSidebarSm && mainSidebar,
          }"
        -->
        <div class="main-content flex-1" @click.self="focusMainBackdrop()">
          <div
            class="flex-1"
            :class="{
              'h-full': fullHeight,
            }"
            @click.self="focusMainBackdrop()"
          >
            <slot name="main-content"></slot>
          </div>
          <slot name="main-footer">
            <footer
              v-if="mainFooter"
              class="
                opacity-70
                md:opacity-95
                text-xs text-gray-500
                justify-center
                md:justify-between
                items-center
                md:items-end md:grid md:grid-cols-2
                gap-2
                px-3
                py-5
                md:text-center
              "
            >
              <div>
                <div class="hidden md:flex flex-col md:flex-row">
                  <span class="mr-2"
                    ><span class="uppercase">{{ appName }}</span> {{ appVersion }}</span
                  >
                  <span class="opacity-80 hover:opacity-100">
                    Made by
                    <a href="https://ucpages.uc.pt/ucframework" target="_blank">UC Framework</a>
                  </span>
                </div>
                <div class="mt-2 md:mt-0 md:text-left">{{ currentYear }} © University of Coimbra</div>
              </div>
              <div class="font-semibold md:text-right">
                <a target="_blank" href="https://www.uc.pt/sobrenos/localizacao_contactos" class="mr-2">Contactos</a>
                <a target="_blank" href="https://apps.uc.pt/forms/view/melhoria" class="mr-2"
                  >Elogios, Sugestões e Reclamações</a
                >
                <a target="_blank" href="https://www.uc.pt/avisolegal" class="mr-2">Aviso Legal</a>
                <a target="_blank" href="https://www.uc.pt/protecao-de-dados">Proteção de Dados</a>
              </div>
            </footer>
          </slot>
        </div>
        <div
          v-if="secondarySidebar"
          class="main-sidebar h-full hidden xl:block flex-shrink-0"
          :class="[secondarySideBarComputed]"
        >
          <div :class="[{ fixed: secondarySidebarFixed }, secondarySideBarComputed]" class="h-full overflow-y-auto">
            <slot name="secondary-sidebar"></slot>
          </div>
        </div>
      </slot>
    </main>
    <div
      v-if="tapbar || this.$slots['tapbar'] || keepTapbar"
      id="tapbar-slot"
      class="block inset-x-0 bottom-0"
      :class="{ 'bg-gray-900 text-white': dark, 'bg-gray-100': !dark, 'lg:hidden': !keepTapbar }"
    >
      <slot name="tapbar"></slot>
    </div>
    <slot name="modals"></slot>
  </div>
</template>

<script>
import AppLogo from '@/components/AppLogo'
import Avatar from '@/fw-modules/fw-core-vue/ui/components/users/Avatar'
import BlockHeaderNotifications from '@/fw-modules/fw-core-vue/notifications/components/blocks/BlockHeaderNotifications'
import BlockHeaderApps from '@/fw-modules/fw-core-vue/id/components/blocks/BlockHeaderApps'
import LayoutBase from './LayoutBase'

export default {
  components: {
    AppLogo,
    Avatar,
    BlockHeaderNotifications,
    BlockHeaderApps,
  },

  mixins: [LayoutBase],
  props: {
    whiteHeader: {
      type: Boolean,
      default: false,
    },
    textHeaderClass: {
      type: String,
      default: '',
    },
    animHeader: {
      type: Boolean,
      default: true,
    },
    headerBackground: {
      type: Boolean,
      default: true,
    },
    headerTitle: {
      type: [String, Object],
      default: '',
    },

    headerLanguage: {
      type: String,
      default: 'pt',
    },
    showHeaderTitle: {
      type: Boolean,
      default: false,
    },
    // Back to page
    // Show arrow to return to previous page
    backTo: {
      type: String,
      default: null,
    },

    animateHeader: {
      type: Boolean,
      default: false,
    },

    disableBackHome: {
      type: Boolean,
      default: false,
    },

    paddingless: {
      type: Boolean,
      default: false,
    },

    disableBackTo: {
      type: Boolean,
      default: false,
    },

    // Page title - on mobile, set on the header
    title: {
      type: String,
      default: null,
    },

    dark: {
      type: Boolean,
      default: false,
    },

    // Show main sidebar
    full: {
      type: Boolean,
      default: false,
    },

    wide: {
      type: Boolean,
      default: false,
    },

    forceExtendedAppLogo: {
      type: Boolean,
      default: false,
    },

    // Show main sidebar
    sidebar: {
      type: Boolean,
      default: false,
    },

    // Show main sidebar
    mainFull: {
      type: Boolean,
      default: false,
    },

    // Show main sidebar
    mainSidebar: {
      type: Boolean,
      default: false,
    },
    secondarySidebar: {
      type: Boolean,
      default: false,
    },

    mainSidebarSm: {
      type: Boolean,
      default: false,
    },

    mainSidebarSize: {
      type: String,
      default: 'w-56',
    },
    secondarySidebarSize: {
      type: String,
      default: 'w-56',
    },

    mainSidebarFixed: {
      type: Boolean,
      default: false,
    },
    secondarySidebarFixed: {
      type: Boolean,
      default: false,
    },

    mainFooter: {
      type: Boolean,
      default: false,
    },

    tapbar: {
      type: Boolean,
      default: false,
    },

    header: {
      type: Boolean,
      default: true,
    },

    keepTapbar: {
      type: Boolean,
      default: false,
    },

    headerToolbar: {
      type: Boolean,
      default: true,
    },

    // Show header user session dropdown
    headerSessionNav: {
      type: Boolean,
      default: true,
    },

    headerAvatar: {
      type: Boolean,
      default: false,
    },

    headerApps: {
      type: Boolean,
      default: true,
    },

    hideHeaderAppsMobile: {
      type: Boolean,
      default: false,
    },

    headerNotifications: {
      type: Boolean,
      default: true,
    },

    noScroll: {
      type: Boolean,
      default: false,
    },

    fullHeight: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    secondarySideBarComputed() {
      return this.secondarySidebarSize
    },
    mainSideBarComputed() {
      return this.mainSidebarSm ? 'w-72' : this.mainSidebarSize
    },
    userLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    user() {
      return this.$store.getters.getUser
    },
    isAnonymous() {
      return this.user && this.user.isAnonymous
    },
    isMobile() {
      return this.window.width < 640
    },
    unread() {
      return this.$store.getters.getUnreadNotificationsCount
    },
    notifications() {
      return this.$store.getters.getUnreadNotifications
    },
    currentYear() {
      const now = new Date()
      return now.getFullYear()
    },
  },
  methods: {
    focusMainBackdrop() {
      this.$emit('click-background')
    },
    getBackTo() {
      if (this.backTo == '-1') this.$router.back()
      if (this.backTo == 'emit') this.$emit('go-back-to')
      else this.$router.push(this.backTo)
    },
    backToHome() {
      if (!this.disableBackTo) {
        this.$router.push('/')
      }
    },
    goToNotification(key) {
      const route = this.$router.currentRoute
      if (!route || route.name != 'notification' || route.params.key != key) {
        this.$router.push({ name: 'notification', params: { key: key } })
      }
    },
  },
}
</script>
<style>
/*.main-header .anim-header {
  transform: translate(0px, -150%);
  transition: all 200ms;
}
.main-header:hover .anim-header {
  transform: translate(0px, 0%);
}*/

.anim-title {
  @apply opacity-100 translate-y-0;
}
.restore-title {
  @apply opacity-0 translate-y-8;
}
.anim-subtags {
  @apply opacity-100;
}
.restore-subtags {
  @apply opacity-0;
}
.force-hover-opacicty-100:hover {
  opacity: 1 !important;
}
.w-105 {
  width: 26.5rem;
}
</style>
