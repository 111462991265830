<template>
  <div id="app" data-muted="true">
    <router-view v-if="activated" />
    <ModalActivateTablet v-else show @activated="refresh()" />
  </div>
</template>

<script>
import ModalActivateTablet from './components/modals/ModalActivateTablet.vue'
export default {
  name: 'App',
  components: {
    ModalActivateTablet,
  },
  data() {
    return {
      backupTimer: null,
    }
  },
  computed: {
    activated() {
      return localStorage.getItem('deviceKey')
    },
  },
  created() {
    //parse url options
    let location = window.location.href
    let url = new URL(location)
    let debug = url.searchParams.get('debug')
    if (debug == 'true') {
      localStorage.setItem('debug', true)
    } else if (debug == 'false') {
      localStorage.removeItem('debug')
    }
  },
  mounted() {
    if (document.getElementById('app')) {
      //add click listner to app
      document.getElementById('app').addEventListener('click', () => {
        this.onClick()
      })
      //start inactivity monitor
      this.$store.dispatch('startInactivityMonitor')
    }
    /*this.saveBackups()
    //backup system
    this.backupTimer = setInterval(() => {
      this.saveBackups()
    }, 1000 * 30)*/
  },
  beforeDestroy() {
    //remove click listner to app
    if (document.getElementById('app')) {
      document.getElementById('app').removeEventListener('click', () => {
        this.onClick()
      })
    }
    //stop backup system
    clearInterval(this.backupTimer)
  },
  methods: {
    /*saveBackups() {
      console.log('=== BACKUP SYSTEM ===')
      for (var i = 0, len = localStorage.length; i < len; i++) {
        var key = localStorage.key(i)
        let parts = key.split('_')
        if (parts[0] == 'backup') {
          let user = parts[1]
          let exam = parts[2]
          let data = JSON.parse(localStorage[i])
          console.log('BACKUP', user, exam, data)
        }
      }
    },*/
    onClick() {
      this.$store.dispatch('setLastActivity')
    },
    refresh() {
      window.location.reload()
    },
  },
}
</script>
