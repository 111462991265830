import CoreActions from '@/fw-modules/fw-core-vue/store/actions'
import { DialogProgrammatic as Dialog } from 'buefy'
import { SnackbarProgrammatic as Snackbar } from 'buefy'

export default Object.assign(CoreActions, {
  // Your actions here
  setLastActivity({ state, commit }) {
    commit('setLastActivity', Date.now())
    commit('setInactivityWarning1', false)
    commit('setInactivityWarning2', false)
    if (state.inactivitySnackbar != null) {
      state.inactivitySnackbar.close()
      commit('setInactivitySnackbar', null)
    }
  },
  startInactivityMonitor({ state, commit, getters, dispatch }) {
    if (state.inactivityTimer == null) {
      //mark fist activity
      dispatch('setLastActivity')
      commit(
        'setInactivityTimer',
        setInterval(() => {
          if (
            getters.isLoggedIn &&
            state.currentRoute != null &&
            !state.inactivityTimerConfig.ignoreRoutes.includes(state.currentRoute.name)
          ) {
            if (Date.now() - state.lastActivity > state.inactivityTimerConfig.logout) {
              console.log('INACTIVITY MONITOR: Forced logout')
              dispatch('logout')
              dispatch('setLastActivity')
              //select div with the class dialog modal is-active
              let dialog = document.querySelector('div.dialog.modal.is-active')
              if (dialog != null) {
                dialog.remove()
              }
            } else if (
              Date.now() - state.lastActivity > state.inactivityTimerConfig.warning1 &&
              !state.inactivityWarnings.warning1
            ) {
              console.log('INACTIVITY MONITOR: Warning 1')
              dispatch('showInactivityWarning', 'warning1')
            } else if (
              Date.now() - state.lastActivity > state.inactivityTimerConfig.warning2 &&
              !state.inactivityWarnings.warning2
            ) {
              console.log('INACTIVITY MONITOR: Warning 2')
              dispatch('showInactivityWarning', 'warning2')
            }
          }
        }, 1000)
      )
    }
  },
  showInactivityWarning({ state, commit, dispatch }, warning) {
    let ms = state.inactivityTimerConfig['logout'] - state.inactivityTimerConfig[warning]
    //let seconds = Math.floor((ms % 60000) / 1000)
    let seconds = Math.floor(ms / 1000)
    let minutes = Math.floor(seconds / 60)
    if (warning == 'warning1') {
      commit('setInactivityWarning1', true)
      commit(
        'setInactivitySnackbar',
        Snackbar.open({
          message:
            'A sua sessão irá terminar em ' +
            (minutes == 1 ? '1 minuto' : minutes > 0 ? minutes + ' minutos' : seconds + ' segundos'),
          type: 'is-warning',
          position: 'is-bottom-right',
          actionText: 'Manter sessão ativa',
          indefinite: true,
          onAction: () => {
            dispatch('setLastActivity')
          },
        })
      )
    } else if (warning == 'warning2') {
      commit('setInactivityWarning2', true)
      Dialog.alert({
        title: 'Aviso de inatividade',
        message:
          'A sua sessão irá terminar automaticamente em ' +
          (minutes == 1 ? '1 minuto' : minutes > 0 ? minutes + ' minutos' : seconds + ' segundos') +
          '. Caso pretenda manter a sessão ativa, clique em "Manter sessão ativa".',
        type: 'is-danger',
        ariaRole: 'alertdialog',
        ariaModal: true,
        confirmText: 'Manter sessão ativa',
        onConfirm: () => {
          dispatch('setLastActivity')
        },
      })
    }
  },
})
