var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fw-layout',{staticClass:"digitalpad",class:{
    font15x: _vm.fontSize > 1.25 && _vm.fontSize < 1.8,
    font20x: _vm.fontSize >= 1.8,
  },style:({ fontSize: _vm.calculatedFontSize }),attrs:{"header":false,"full":"","loading":_vm.initialLoading,"mobile-ready":true,"loading-icon":"live","loading-title":"UC Digital Pad"},scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('div',{staticClass:"fixed bottom-1 right-2 text-xs text-black opacity-20 z-0"},[_vm._v(_vm._s(_vm.appName)+" "+_vm._s(_vm.appVersion))]),_c('div',{staticClass:"h-full overflow-auto w-2/4 bg-gray-900 px-3 flex flex-col gap-20 relative"},[_c('div',{staticClass:"flex text-white absolute top-5 left-5 z-10 items-center gap-3",on:{"mousedown":function($event){return _vm.debugMode('start')},"mouseleave":function($event){return _vm.debugMode('end')},"mouseup":function($event){return _vm.debugMode('end')},"touchstart":function($event){return _vm.debugMode('start')},"touchend":function($event){return _vm.debugMode('end')}}},[_c('div',[_c('AppLogo')],1),(_vm.activatingDebug && _vm.isDev)?_c('div',{staticClass:"h-4 w-4 rounded-full bg-white animate-pulse"}):(_vm.debugActive && _vm.isDev)?_c('fw-tag',{attrs:{"type":"light-orange"}},[_vm._v("DEBUG")]):_vm._e()],1),_c('div',{staticClass:"flex-1 select-none adapt-text flex flex-col gap-10 text-gray-100 relative overflow-hidden",on:{"mousedown":_vm.startDrag,"mouseup":_vm.endDrag,"mousemove":_vm.dragging,"touchstart":_vm.startDrag,"touchend":_vm.endDrag,"touchmove":_vm.dragging}},[_c('div',{staticClass:"flex flex-col absolute top-1/2 left-20 right-20 transition-all",style:({ transform: 'translateY(' + (_vm.page * -11 - 5.5) + 'rem)' })},[_c('div',{staticClass:"text-3xl h-44 font-semibold transition-all flex flex-col justify-center",class:{
              'opacity-50': _vm.page != 0,
              'opacity-100': _vm.page == 0,
            }},[_vm._v(" "+_vm._s(_vm.$t('welcome'))+" "),_c('div',{staticClass:"text-xl font-medium"},[_vm._v(_vm._s(_vm.$t('instructions')))])]),_c('div',{staticClass:"text-2xl font-medium h-44 transition-all flex flex-col justify-center",class:{
              'opacity-50': _vm.page != 1,
              'opacity-100': _vm.page == 1,
            }},[_vm._v(" "+_vm._s(_vm.$t('step1'))+" ")]),_c('div',{staticClass:"text-2xl font-medium h-44 transition-all flex flex-col justify-center",class:{
              'opacity-50': _vm.page != 2,
              'opacity-100': _vm.page == 2,
            }},[_vm._v(" "+_vm._s(_vm.$t('step2'))+" ")]),_c('div',{staticClass:"text-2xl font-medium h-44 transition-all flex flex-col justify-center",class:{
              'opacity-50': _vm.page != 3,
              'opacity-100': _vm.page == 3,
            }},[_vm._v(" "+_vm._s(_vm.$t('step3'))+" ")]),_c('div',{staticClass:"text-2xl font-medium h-44 transition-all flex flex-col justify-center",class:{
              'opacity-50': _vm.page != 4,
              'opacity-100': _vm.page == 4,
            }},[_vm._v(" "+_vm._s(_vm.$t('step4'))+" ")]),_c('div',{staticClass:"text-2xl font-medium h-44 transition-all flex flex-col justify-center",class:{
              'opacity-50': _vm.page != 5,
              'opacity-100': _vm.page == 5,
            }},[_vm._v(" "+_vm._s(_vm.$t('step5'))+" "),_c('div',{staticClass:"text-base font-normal text-gray-300 mt-1"},[_vm._v(" "+_vm._s(_vm.$t('step5b'))+" ")])]),_c('div',{staticClass:"text-2xl font-medium h-44 transition-all flex flex-col justify-center",class:{
              'opacity-50': _vm.page != 6,
              'opacity-100': _vm.page == 6,
            }},[_vm._v(" "+_vm._s(_vm.$t('step6'))+" ")])])]),_c('div',{staticClass:"flex absolute top-0 bottom-0 left-5 flex-col gap-4 items-center justify-center"},[_c('div',{staticClass:"h-9 w-9 cursor-pointer flex justify-center items-center leading-9 text-center font-semibold text-lg rounded-full",class:{
            'bg-white text-black': _vm.page == 0,
            'bg-gray-800 text-white': _vm.page != 0,
          },on:{"click":function($event){_vm.page = 0}}},[_c('div',{staticClass:"h-4 w-4 border-2 rounded-full",class:{
              'border-black': _vm.page == 0,
              'border-white': _vm.page != 0,
            }})]),_c('div',{staticClass:"h-9 w-9 cursor-pointer leading-9 text-center font-semibold text-lg rounded-full",class:{
            'bg-white text-black': _vm.page == 1,
            'bg-gray-800 text-white': _vm.page != 1,
          },on:{"click":function($event){_vm.page = 1}}},[_vm._v(" 1 ")]),_c('div',{staticClass:"h-9 w-9 cursor-pointer leading-9 text-center font-semibold text-lg rounded-full",class:{
            'bg-white text-black': _vm.page == 2,
            'bg-gray-800 text-white': _vm.page != 2,
          },on:{"click":function($event){_vm.page = 2}}},[_vm._v(" 2 ")]),_c('div',{staticClass:"h-9 w-9 cursor-pointer leading-9 text-center font-semibold text-lg rounded-full",class:{
            'bg-white text-black': _vm.page == 3,
            'bg-gray-800 text-white': _vm.page != 3,
          },on:{"click":function($event){_vm.page = 3}}},[_vm._v(" 3 ")]),_c('div',{staticClass:"h-9 w-9 cursor-pointer leading-9 text-center font-semibold text-lg rounded-full",class:{
            'bg-white text-black': _vm.page == 4,
            'bg-gray-800 text-white': _vm.page != 4,
          },on:{"click":function($event){_vm.page = 4}}},[_vm._v(" 4 ")]),_c('div',{staticClass:"h-9 w-9 cursor-pointer leading-9 text-center font-semibold text-lg rounded-full",class:{
            'bg-white text-black': _vm.page == 5,
            'bg-gray-800 text-white': _vm.page != 5,
          },on:{"click":function($event){_vm.page = 5}}},[_vm._v(" 5 ")]),_c('div',{staticClass:"h-9 w-9 cursor-pointer leading-9 text-center font-semibold text-lg rounded-full",class:{
            'bg-white text-black': _vm.page == 6,
            'bg-gray-800 text-white': _vm.page != 6,
          },on:{"click":function($event){_vm.page = 6}}},[_vm._v(" 6 ")]),_c('div',{staticClass:"h-9 w-9 cursor-pointer leading-9 text-center flex items-center justify-center font-semibold text-white bg-gray-800 text-lg rounded-full",class:{
            'opacity-0': _vm.page == 6,
            'opacity-100': _vm.page != 6,
          },on:{"click":function($event){return _vm.next()}}},[_c('fw-icon-arrow-down',{staticClass:"h-6 w-6"})],1)])]),_c('div',{staticClass:"h-full w-2/4 flex flex-col adapt-text justify-center items-center relative"},[_c('div',{staticClass:"flex absolute top-4 right-5 justify-start"},[_c('HeaderLanguagePanel'),_c('div',{staticClass:"flex flex-col justify-end items-end pt-1"},[_c('div',{staticClass:"text-lg font-semibold flex items-center w-22 text-black"},[_c('div',{staticClass:"hour w-6 text-right"},[_vm._v(_vm._s(_vm.clock.hours || '00'))]),_c('div',{staticClass:"text-gray-500"},[_vm._v(":")]),_c('div',{staticClass:"min w-6 text-right"},[_vm._v(_vm._s(_vm.clock.minutes || '00'))]),_c('div',{staticClass:"text-gray-500"},[_vm._v(":")]),_c('div',{staticClass:"text-gray-500 w-5 text-right"},[_vm._v(_vm._s(_vm.clock.seconds || '00'))])]),_c('div',{staticClass:"text-xs font-medium text-gray-400 text-center w-22"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.today))+" ")])])],1),_c('div',{staticClass:"mx-auto max-w-4xl"},[_c('ModalEnterExam',{attrs:{"show":_vm.showModalExam},on:{"close":function($event){return _vm.openExamModal(false)}}})],1)]),_c('div',{staticClass:"hidden fixed select-none sm:flex bottom-5 right-5 justify-end gap-3 opacity-70 hover:opacity-100"},[_c('fw-button',{staticClass:"w-12",attrs:{"type":"xlight","size":"sm"},nativeOn:{"click":function($event){return _vm.changeFontSize('less')}}},[_vm._v("A")]),_c('fw-button',{staticClass:"text-2xl w-12",attrs:{"type":"xlight","size":"sm"},nativeOn:{"click":function($event){return _vm.changeFontSize('more')}}},[_vm._v("A")])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }