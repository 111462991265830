<template>
  <fw-layout
    :header="false"
    full
    :loading="initialLoading"
    :mobile-ready="true"
    loading-icon="live"
    loading-title="UC Digital Pad"
    class="digitalpad"
    :class="{
      font15x: fontSize > 1.25 && fontSize < 1.8,
      font20x: fontSize >= 1.8,
    }"
    :style="{ fontSize: calculatedFontSize }"
  >
    <template #main>
      <div class="fixed bottom-1 right-2 text-xs text-black opacity-20 z-0">{{ appName }} {{ appVersion }}</div>
      <div class="h-full overflow-auto w-2/4 bg-gray-900 px-3 flex flex-col gap-20 relative">
        <div
          class="flex text-white absolute top-5 left-5 z-10 items-center gap-3"
          @mousedown="debugMode('start')"
          @mouseleave="debugMode('end')"
          @mouseup="debugMode('end')"
          @touchstart="debugMode('start')"
          @touchend="debugMode('end')"
        >
          <div><AppLogo /></div>
          <div v-if="activatingDebug && isDev" class="h-4 w-4 rounded-full bg-white animate-pulse"></div>
          <fw-tag v-else-if="debugActive && isDev" type="light-orange">DEBUG</fw-tag>
        </div>
        <div
          class="flex-1 select-none adapt-text flex flex-col gap-10 text-gray-100 relative overflow-hidden"
          @mousedown="startDrag"
          @mouseup="endDrag"
          @mousemove="dragging"
          @touchstart="startDrag"
          @touchend="endDrag"
          @touchmove="dragging"
        >
          <div
            class="flex flex-col absolute top-1/2 left-20 right-20 transition-all"
            :style="{ transform: 'translateY(' + (page * -11 - 5.5) + 'rem)' }"
          >
            <div
              class="text-3xl h-44 font-semibold transition-all flex flex-col justify-center"
              :class="{
                'opacity-50': page != 0,
                'opacity-100': page == 0,
              }"
            >
              {{ $t('welcome') }}
              <div class="text-xl font-medium">{{ $t('instructions') }}</div>
            </div>
            <div
              class="text-2xl font-medium h-44 transition-all flex flex-col justify-center"
              :class="{
                'opacity-50': page != 1,
                'opacity-100': page == 1,
              }"
            >
              {{ $t('step1') }}
            </div>
            <div
              class="text-2xl font-medium h-44 transition-all flex flex-col justify-center"
              :class="{
                'opacity-50': page != 2,
                'opacity-100': page == 2,
              }"
            >
              {{ $t('step2') }}
            </div>
            <div
              class="text-2xl font-medium h-44 transition-all flex flex-col justify-center"
              :class="{
                'opacity-50': page != 3,
                'opacity-100': page == 3,
              }"
            >
              {{ $t('step3') }}
            </div>
            <div
              class="text-2xl font-medium h-44 transition-all flex flex-col justify-center"
              :class="{
                'opacity-50': page != 4,
                'opacity-100': page == 4,
              }"
            >
              {{ $t('step4') }}
            </div>
            <div
              class="text-2xl font-medium h-44 transition-all flex flex-col justify-center"
              :class="{
                'opacity-50': page != 5,
                'opacity-100': page == 5,
              }"
            >
              {{ $t('step5') }}
              <div class="text-base font-normal text-gray-300 mt-1">
                {{ $t('step5b') }}
              </div>
            </div>
            <div
              class="text-2xl font-medium h-44 transition-all flex flex-col justify-center"
              :class="{
                'opacity-50': page != 6,
                'opacity-100': page == 6,
              }"
            >
              {{ $t('step6') }}
            </div>
          </div>
        </div>
        <div class="flex absolute top-0 bottom-0 left-5 flex-col gap-4 items-center justify-center">
          <div
            class="h-9 w-9 cursor-pointer flex justify-center items-center leading-9 text-center font-semibold text-lg rounded-full"
            :class="{
              'bg-white text-black': page == 0,
              'bg-gray-800 text-white': page != 0,
            }"
            @click="page = 0"
          >
            <div
              class="h-4 w-4 border-2 rounded-full"
              :class="{
                'border-black': page == 0,
                'border-white': page != 0,
              }"
            ></div>
          </div>
          <div
            class="h-9 w-9 cursor-pointer leading-9 text-center font-semibold text-lg rounded-full"
            :class="{
              'bg-white text-black': page == 1,
              'bg-gray-800 text-white': page != 1,
            }"
            @click="page = 1"
          >
            1
          </div>
          <div
            :class="{
              'bg-white text-black': page == 2,
              'bg-gray-800 text-white': page != 2,
            }"
            class="h-9 w-9 cursor-pointer leading-9 text-center font-semibold text-lg rounded-full"
            @click="page = 2"
          >
            2
          </div>
          <div
            :class="{
              'bg-white text-black': page == 3,
              'bg-gray-800 text-white': page != 3,
            }"
            class="h-9 w-9 cursor-pointer leading-9 text-center font-semibold text-lg rounded-full"
            @click="page = 3"
          >
            3
          </div>
          <div
            :class="{
              'bg-white text-black': page == 4,
              'bg-gray-800 text-white': page != 4,
            }"
            class="h-9 w-9 cursor-pointer leading-9 text-center font-semibold text-lg rounded-full"
            @click="page = 4"
          >
            4
          </div>
          <div
            :class="{
              'bg-white text-black': page == 5,
              'bg-gray-800 text-white': page != 5,
            }"
            class="h-9 w-9 cursor-pointer leading-9 text-center font-semibold text-lg rounded-full"
            @click="page = 5"
          >
            5
          </div>
          <div
            :class="{
              'bg-white text-black': page == 6,
              'bg-gray-800 text-white': page != 6,
            }"
            class="h-9 w-9 cursor-pointer leading-9 text-center font-semibold text-lg rounded-full"
            @click="page = 6"
          >
            6
          </div>
          <div
            :class="{
              'opacity-0': page == 6,
              'opacity-100': page != 6,
            }"
            class="h-9 w-9 cursor-pointer leading-9 text-center flex items-center justify-center font-semibold text-white bg-gray-800 text-lg rounded-full"
            @click="next()"
          >
            <fw-icon-arrow-down class="h-6 w-6" />
          </div>
        </div>
      </div>
      <div class="h-full w-2/4 flex flex-col adapt-text justify-center items-center relative">
        <div class="flex absolute top-4 right-5 justify-start">
          <HeaderLanguagePanel />
          <div class="flex flex-col justify-end items-end pt-1">
            <div class="text-lg font-semibold flex items-center w-22 text-black">
              <div class="hour w-6 text-right">{{ clock.hours || '00' }}</div>
              <div class="text-gray-500">:</div>
              <div class="min w-6 text-right">{{ clock.minutes || '00' }}</div>
              <div class="text-gray-500">:</div>
              <div class="text-gray-500 w-5 text-right">{{ clock.seconds || '00' }}</div>
            </div>
            <div class="text-xs font-medium text-gray-400 text-center w-22">
              {{ today | formatDate }}
            </div>
          </div>
        </div>
        <div class="mx-auto max-w-4xl">
          <ModalEnterExam :show="showModalExam" @close="openExamModal(false)" />
        </div>
      </div>
      <!-- Accessibility -->
      <div class="hidden fixed select-none sm:flex bottom-5 right-5 justify-end gap-3 opacity-70 hover:opacity-100">
        <fw-button type="xlight" size="sm" class="w-12" @click.native="changeFontSize('less')">A</fw-button>
        <fw-button type="xlight" size="sm" class="text-2xl w-12" @click.native="changeFontSize('more')">A</fw-button>
      </div>
    </template>
  </fw-layout>
</template>

<script>
// import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import AppLogo from '@/components/AppLogo'
import ModalEnterExam from '../components/modals/ModalEnterExam'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import AcademicUtilities from '@/fw-modules/fw-core-vue/academic/services/utilities'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/ui/legacy/components/header/HeaderLanguagePanel'

export default {
  components: {
    AppLogo,
    ModalEnterExam,
    HeaderLanguagePanel,
  },

  data() {
    return {
      debugActive: localStorage.getItem('fw-exam-debug') == 'true',
      activatingDebug: false,
      debugModeTimer: null,
      startPos: null,
      startPage: null,
      page: 0,
      showModalExam: false,
      initialLoading: true,
      loading: false,
      classesLoaded: false,

      // Helper
      users: {},

      // Classes
      activeSessions: [],
      todaySessions: [],
      thisWeekSessions: [],
      nextWeekSessions: [],
      nextSessionInterval: null,

      // Clock and date
      today: new Date(),
      clock: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      clockInterval: null,
      fontSize: 1,
      appVersion: process.env.VUE_APP_VERSION,
      appName: process.env.VUE_APP_KEY,
    }
  },

  computed: {
    isDev() {
      return process.env.NODE_ENV === 'development'
    },
    calculatedFontSize() {
      return this.fontSize * 100 + '%'
    },
    isDigitalPad() {
      return process.env.VUE_APP_KEY == 'ucdigitalpad'
    },
    user() {
      return this.$store.getters.getUser
    },
    meeting() {
      if (this.user && this.user.meeting) return this.user.meeting
      else return null
    },
    logoutUrl() {
      // return FwEnvConfig.appUrlUCId + '/logout'
      return '/logout'
    },
    // New notifications and chat messages
    unreadNotifications() {
      return this.$store.getters.getUnreadNotificationsCount
    },
    unreadChatMessages() {
      return this.$store.getters.getUnreadChatMessagesCount
    },

    // Checks / validations
    isTeacher() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('teacher'))
    },
    isStudent() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('student'))
    },
    hasClasses() {
      return (
        this.activeSessions.length ||
        this.todaySessions.length ||
        this.thisWeekSessions.length ||
        this.nextWeekSessions.length
      )
    },
  },

  watch: {
    user() {
      console.log('user changed')
      this.start()
    },
  },

  created() {
    if (localStorage.getItem('pin_instance_key')) {
      let instance_key = localStorage.getItem('pin_instance_key')
      //remove instance key from local storage
      localStorage.removeItem('pin_instance_key')
      this.$router.push({ name: 'exams-examination', params: { key: instance_key } })
    }
  },

  mounted() {
    this.$store.dispatch('setUser')
    this.openExamModal(true)

    this.setClock()
    this.start()
    this.loadFontSize()
    setTimeout(() => {
      this.initialLoading = false
    }, 1500)
  },

  beforeDestroy() {
    if (this.nextSessionInterval) clearInterval(this.nextSessionInterval)
    if (this.clockInterval) clearInterval(this.clockInterval)
  },

  methods: {
    debugMode(action) {
      if (!this.isDev) return
      if (action == 'start') {
        this.activatingDebug = true
        this.debugModeTimer = setTimeout(() => {
          this.activatingDebug = false
          if (this.debugActive) {
            localStorage.removeItem('fw-exam-debug')
            this.debugActive = false
          } else {
            localStorage.setItem('fw-exam-debug', 'true')
            this.debugActive = true
          }
          //refresh page
          window.location.reload()
        }, 3000)
      } else {
        clearTimeout(this.debugModeTimer)
        this.activatingDebug = false
      }
    },
    dragging(e) {
      if (this.startPos) {
        //make compatible with touch
        let currentY = e.touches ? e.touches[0].clientY : e.clientY
        //let x = e.clientX - this.startPos.x
        let y = currentY - this.startPos
        //this.$el.style.transform = `translate(${x}px, ${y}px)`
        //page from 0 to 6, 0 is the first page and each page is 8.5rem
        let newPage = this.startPage - Math.round(y / 100)
        this.page = Math.min(6, Math.max(0, newPage))
      }
    },
    startDrag(e) {
      console.log('start drag', e)
      this.startPage = this.page
      let currentY = e.touches ? e.touches[0].clientY : e.clientY
      this.startPos = currentY
    },
    endDrag(e) {
      console.log('end drag', e)
      this.startPos = null
      this.startPage = null
    },
    next() {
      if (this.page < 6) {
        this.page++
      }
    },
    openExamModal(show = true) {
      this.showModalExam = show
    },
    async loadFontSize() {
      if (this.isDigitalPad) {
        let fontSize = await ServiceSettings.getSetting('tabletFontSize')
        if (fontSize && fontSize.value) {
          this.fontSize = parseFloat(fontSize.value)
        }
      }
    },
    async changeFontSize(option) {
      if (option == 'more' && this.fontSize < 2) {
        this.fontSize += 0.25
      } else if (option == 'less' && this.fontSize > 1) {
        this.fontSize -= 0.25
      }
      //TODO: Save to tablet profile (if we have one)
      if (this.isDigitalPad) {
        await ServiceSettings.setSetting('tabletFontSize', this.fontSize)
      }
    },
    start() {
      if (this.classesLoaded) return
    },
    async getNextClassesSessions() {
      let sessions = []
      if (this.isTeacher) {
        sessions = await ServiceAcademic.getNextTeacherSessions()
      } else if (this.isStudent) {
        sessions = await ServiceAcademic.getNextStudentSessions()
      }
      if (sessions) {
        AcademicUtilities.buildSessionDates(
          sessions,
          this.activeSessions,
          this.todaySessions,
          this.thisWeekSessions,
          this.nextWeekSessions
        )
        this.nextSessionInterval = setInterval(this.checkSessionDates, 30 * 1000)
      }
    },
    spaceAndBuildingText(session) {
      if (session.space && session.building) {
        return session.space.title + ' ' + session.building.title
      } else if (session.space) {
        return session.space.title
      } else if (session.building) {
        return session.building.title
      } else {
        return ''
      }
    },
    checkSessionDates() {
      AcademicUtilities.checkSessionDates(
        this.activeSessions,
        this.todaySessions,
        this.thisWeekSessions,
        this.nextWeekSessions
      )
    },

    // Logout
    logout() {
      window.location.href = this.logoutUrl
    },

    // Clock
    setClock() {
      this.clockInterval = setInterval(() => {
        const date = new Date()
        this.clock.hours = date.getHours()
        this.clock.minutes = this.checkSingleDigit(date.getMinutes())
        this.clock.seconds = this.checkSingleDigit(date.getSeconds())
      }, 1000)
    },
    checkSingleDigit(digit) {
      return ('0' + digit).slice(-2)
    },
  },
}
</script>

<style lang="scss">
.digitalpad .empty-tab {
  @apply h-full flex items-center justify-center text-center text-gray-500 rounded-2xl flex-col gap-5;
}
.digitalpad.font15x .adapt-text .text-sm {
  @apply text-base;
}
.digitalpad.font15x .adapt-text .text-base {
  @apply text-lg;
}
.digitalpad.font15x .adapt-text .text-lg {
  @apply text-xl;
}
.digitalpad.font15x .adapt-text .text-xl {
  @apply text-2xl;
}
.digitalpad.font15x .adapt-text .text-2xl {
  @apply text-3xl;
}

.digitalpad.font15x .adapt-text .leading-5 {
  @apply leading-6;
}
.digitalpad.font15x .adapt-text .leading-6 {
  @apply leading-7;
}

.digitalpad.font20x .adapt-text .text-sm {
  @apply text-xl;
}

.digitalpad.font20x .adapt-text .text-base {
  @apply text-2xl;
}

.digitalpad.font20x .adapt-text .text-lg {
  @apply text-3xl;
}
.digitalpad.font20x .adapt-text .text-xl {
  @apply text-4xl;
}
.digitalpad.font20x .adapt-text .text-2xl {
  @apply text-5xl;
}

.digitalpad.font20x .adapt-text .leading-5 {
  @apply leading-7;
}
.digitalpad.font20x .adapt-text .leading-6 {
  @apply leading-8;
}
</style>

<i18n>
{
  "pt": {
    "hello": "Olá",
    "welcome": "Bem-vindo ao UC Digital Pad!",
    "instructions": "Instruções para o exame:",
    "step1": "Insira o código do exame.",
    "step2": "Aceite os termos de utilização.",
    "step3": "Aguarde que o exame comece.",
    "step4": "Mostre o que sabe respondendo ao exame. Boa sorte!",
    "step5": "Quando terminar, clique em 'Entregar exame'.",
    "step5b": "Pst.. Se não estiver nos seus dias mais brilhantes pode desistir a qualquer momento clicando em 'Desistir do exame'.",
    "step6": "Devolva o tablet ao vigilante mais próximo."
  },
  "en": {
    "hello": "Hello",
    "welcome": "Welcome to UC Digital Pad!",
    "instructions": "Instructions for the exam:",
    "step1": "Enter the code for the exam.",
    "step2": "Accept the terms of use.",
    "step3": "Wait for the exam to start.",
    "step4": "Show what you know by answering the exam. Good luck!",
    "step5": "When you finish, click on 'Submit exam'.",
    "step5b": "Pst.. If you're not having your brightest day, you can quit at any time by clicking on 'Quit exam'.",
    "step6": "Return the tablet to the nearest invigilator."
  }
}
</i18n>
