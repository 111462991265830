<template>
  <fw-modal :active.sync="isActive" :can-cancel="true" size="full" :width="showKeyboard ? '50rem' : '27rem'">
    <div class="flex flex-col gap-10 w-full">
      <div class="flex-1 flex flex-col gap-0">
        <fw-heading size="h2">Iniciar sessão com PIN</fw-heading>
        Insira o seu PIN de utilização única aqui:
        <fw-label class="mb-1 mt-2">PIN de utilização única:</fw-label>
        <form class="flex flex-col gap-3" @submit.prevent="loginPin">
          <div class="flex justify-center items-center gap-3">
            <b-input
              id="code1"
              ref="code1"
              v-model="code1"
              type="number"
              min="0"
              max="9"
              class="codeinput"
              :class="{ error: errorMessage }"
              placeholder=""
              @input="nextCode(1)"
            />
            <b-input
              id="code2"
              ref="code2"
              v-model="code2"
              type="number"
              min="0"
              max="9"
              class="codeinput"
              :class="{ error: errorMessage }"
              placeholder=""
              @input="nextCode(2)"
            />
            <b-input
              id="code3"
              ref="code3"
              v-model="code3"
              type="number"
              min="0"
              max="9"
              class="codeinput"
              :class="{ error: errorMessage }"
              placeholder=""
              @input="nextCode(3)"
            />
            <div class="text-gray-400 text-4xl">-</div>
            <b-input
              id="code4"
              ref="code4"
              v-model="code4"
              min="0"
              max="9"
              type="number"
              class="codeinput"
              :class="{ error: errorMessage }"
              placeholder=""
              @input="nextCode(4)"
            />
            <b-input
              id="code5"
              ref="code5"
              v-model="code5"
              min="0"
              max="9"
              type="number"
              class="codeinput"
              :class="{ error: errorMessage }"
              placeholder=""
              @input="nextCode(5)"
            />
            <b-input
              id="code6"
              ref="code6"
              v-model="code6"
              min="0"
              max="9"
              type="number"
              class="codeinput"
              :class="{ error: errorMessage }"
              placeholder=""
              @input="nextCode(6)"
            />
          </div>
          <div
            v-if="errorMessage"
            class="h-10 p-1 flex items-center justify-center text-center font-semibold rounded-md text-sm"
            :class="{ 'bg-red-500 bg-opacity-10 text-red-700': errorMessage }"
          >
            {{ errorMessage }}
          </div>
          <div class="mt-5">
            <fw-button
              type="black"
              size="md"
              :disabled="fullCode.length != 6 || loading"
              :loading="loading"
              expanded
              @click.native="loginPin"
              >Iniciar Sessão</fw-button
            >
          </div>
          <div>
            <fw-button type="xlight" size="md" expanded @click.native="$emit('close')">Cancelar</fw-button>
          </div>
        </form>
      </div>
    </div>
  </fw-modal>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  props: {
    showKeyboard: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: '',
      isActive: true,
      loading: false,
      errorMessage: '',
      showCode: false,
    }
  },

  computed: {
    me() {
      return this.$store.getters.getUser
    },
    fullCode() {
      return `${this.code1}${this.code2}${this.code3}${this.code4}${this.code5}${this.code6}`
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
    isMobile() {
      return this.window.width < 640
    },
  },

  mounted() {
    //focus on code1
    this.$refs.code1.focus()
    document.addEventListener('keydown', this.listenKeyboard)
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.listenKeyboard)
  },

  methods: {
    listenKeyboard(e) {
      if (e.key === 'Escape') {
        this.closeModal()
      } else if (e.key === 'Enter') {
        this.loginPin()
      } else if (e.key === 'Backspace') {
        //get the current focus element
        let id = document.activeElement.id
        let number = parseInt(id.replace('code', ''))
        if (number > 0) {
          this.deletedCode(number)
        }
      }
      //use left and right arrows to focus in diferent code
      else if (e.key === 'ArrowLeft') {
        let id = document.activeElement.id
        let number = parseInt(id.replace('code', ''))
        if (number > 1) {
          let ref = 'code' + (number - 1)
          if (this.$refs[ref]) {
            this.$refs[ref].focus()
          }
        }
      } else if (e.key === 'ArrowRight') {
        let id = document.activeElement.id
        let number = parseInt(id.replace('code', ''))
        if (number < 6) {
          let ref = 'code' + (number + 1)
          if (this.$refs[ref]) {
            this.$refs[ref].focus()
          }
        }
      }
    },
    deletedCode(id) {
      console.log('deletedCode', id)
      let ref = 'code' + id
      let prev = 'code' + (id - 1)
      let next = 'code' + (id + 1)
      if (this.$refs[prev] && this[ref] == '') {
        this.$refs[prev].focus()
      } else {
        if (this[ref].length == 1 && this.$refs[next]) {
          this.$refs[next].focus()
        }
      }
    },
    nextCode(id) {
      if (id < 6) {
        if (this['code' + id] != '') {
          let next = 'code' + (id + 1)
          if (this.$refs[next]) {
            this.$refs[next].focus()
          }
        }
      }
    },
    async loginPin() {
      if (this.fullCode) {
        this.loading = true
        // Reset message
        this.errorMessage = ''
        this.$store
          .dispatch('loginPIN', {
            pin: this.fullCode,
          })
          .then(response => {
            this.loading = false
            this.$router.push('/')
            return response
          })
          .catch(error => {
            this.loading = false
            this.errorMessage = utils.errors(error).getKey() || 'Undefined'
            console.warn(`login error: ${this.errorMessage}`, error)
          })
      }
    },
  },
}
</script>

<style>
.control.codeinput input {
  width: 3rem;
  font-size: 1.5rem;
  text-align: center;
  padding: 0px;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: #fafafa;
}

.control.codeinput.error input {
  border: 1px solid #d74a4a;
  color: #d74a4a;
}

.control.codeinput input::-webkit-outer-spin-button,
.control.codeinput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.control.codeinput input[type='number'] {
  -moz-appearance: textfield;
}
</style>
