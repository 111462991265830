import NotFound from '@/fw-modules/fw-core-vue/id/views/NotFound'
import Forbidden from '@/fw-modules/fw-core-vue/id/views/Forbidden'
import ViewToken from '@/fw-modules/fw-core-vue/id/views/ViewToken'
import InternalServerError from '@/fw-modules/fw-core-vue/id/views/InternalServerError'

export default [
  {
    path: '*',
    name: 'error',
    component: NotFound,
  },
  {
    path: '/internal-error',
    name: 'internalError',
    component: InternalServerError,
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: Forbidden,
    meta: { requiresAuth: true },
  },
  {
    path: '/t/:token',
    name: 't',
    component: ViewToken,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/fw-modules/fw-core-vue/id/views/Login'),
    meta: { requiresNoAuth: true },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/fw-modules/fw-core-vue/id/views/Logout'),
    meta: { requiresAuth: true },
  },
]
